import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { Container } from '../components/styled';
import Jewelery from '../images/404.svg';

const Container404 = styled(Container)`
  display: flex;
  align-items: center;
  align-content: space-around;
  margin: 2% auto;
  img {
    flex: 1;
    @media (max-width: 1240px) {
      display: none;
    }
  }
  h3 {
    flex: 3;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <Container404>
      <img src={Jewelery} alt="" />
      <h3>404 - Diese Seite existiert leider nicht</h3>
    </Container404>
  </Layout>
);

export default NotFoundPage;
